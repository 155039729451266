import React from "react";
import Container from "../components/Container";
import Fade from "../components/Fade.jsx";
import PageTitle from "../components/PageTitle";
import Title from "../components/Title";

function Home() {
  return (
    <Container>
      <PageTitle>Etusivu</PageTitle>
      <div className="grid grid-cols-1 gap-5 mx-auto md:grid-cols-3 md:gap-16 max-w-7xl">
        <div>
          <img
            src="./images/team.jpg"
            alt="Niina Seila"
            className="w-2/3 mx-auto mt-10 md:w-auto"
          />
        </div>
        <div className="flex items-center col-span-1 md:col-span-2">
          <div className="py-3 text-center md:text-left md:py-0">
            <Fade bottom>
              <Title>Tervetuloa</Title>

              <p className="mb-3">
                Kauneushoitola ja ripsistudio Riihimäellä sinua varten.
                Tarjoamme korkealaatuisia ihonhoito- ja hyvinvointipalveluita
                sinun tarpeisiin. Sinusta huolehtivat kosmetologit Niina ja
                Michaela. Myös ripsienpidennys- ja ripsien kestotaivutus
                palvelut vankalla ammattiataidolla, usean vuoden kokemuksella.
              </p>

              <p className="mb-5">
                Kasvohoidoissa käytämme laadukasta Anna Lotan PRO-tuotesarjaa.
                Tällä sarjalla voimme räätälöidä juurin sinun ihonhoidon
                tarpeisiin kasvohoidon sekä kotihoitotuoteet.
              </p>

              <p>Lämpimästi tervetuloa.</p>
            </Fade>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Home;
