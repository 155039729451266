import React from "react";

export default function ContactInformation() {
  return (
    <>
      <p>Valtakatu 3</p>
      <p>11130 Riihimäki</p>
      <p>
        puh. <a href="tel:045-78325807">045 - 7832 5807</a>
      </p>
      <p>
        <a href="mailto:niina@adelianbeauty.fi">info@adelianbeauty.fi</a>
      </p>
      <p>Y-tunnus: 2740043-4</p>
    </>
  );
}
