import React from "react";
import ContactForm from "../components/ContactForm";
import ContactInformation from "../components/ContactInformation";
import Container from "../components/Container";
import Fade from "../components/Fade.jsx";
import PageTitle from "../components/PageTitle";
import Title from "../components/Title";
import BlockItem from "../layout/BlockItem";

function Contact() {
  return (
    <>
      <PageTitle>Yhteystiedot</PageTitle>
      <Fade>
        <iframe
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAlhATdeAiPkLZq9OBMTg5C5hm8N1hrfbc&q=place_id:ChIJ462XV4wSjkYRPKT67uhPKIg"
          width="100%"
          height="600px"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="contact"
        ></iframe>
      </Fade>
      <Container>
        <div className="grid grid-cols-1 gap-20 md:grid-cols-2 auto-rows-max">
          <div className="col">
            <Fade>
              <ContactForm />
            </Fade>
          </div>
          <div className="h-full">
            <div className="h-full">
              <div>
                <Fade>
                  <Title>Yhteystiedot</Title>
                </Fade>
                <Fade bottom>
                  <ContactInformation />
                  <br />
                  <Title>Pysäköinti</Title>
                  Graniitin aukiolla 2h kiekkopaikkoja. Musiikkiopiston vieressä
                  kiekottomia paikkoja.
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Contact;
