import React from "react";
import Container from "../components/Container";
import Fade from "../components/Fade.jsx";
import PageTitle from "../components/PageTitle.jsx";
import Subtitle from "../components/Subtitle";
import Title from "../components/Title";
import profileImage from "../images/profile.jpg";
function About() {
  const workers = [
    {
      name: "Niina",
      image: "./images/niina.jpg",
      title: "Omistaja",
      about:
        "Olen Niina, sertifioitu ripsiteknikko ja olenkin tehnyt ripsienpidennyksiä vuodesta 2016. Tavoitteeni on kouluttautua joka vuosi lisää ja näin pysyä sen hetken trendeissä mukana. Ala kehittyy myös kovaa vauhtia ja haluankin tarjota asiakkailleni vain parasta. Ripsienpidennykset tehdään turvallisesti, korkealaatuisilla tuotteilla.",
      certificates: [
        "2016 Classic lashes - Golden Eyelashes",
        "2016 Volume lashes - Cristel Studio",
        "2017 Megavolume lashes -Cristel Studio",
        "2017 Perfect russian volumes - SB Lux",
        "2017 2nd place in Finnish Championship of volumelashes",
        "2017 Lash-to-lash technique - Sonja Smatko",
        "2017 Perfect russian volumes - Sonja Smatko",
        "2018 Volume advance - Carmen Palmiste",
        "2019 Advanced volume up to 16D - Polina Glebanova",
        "2020 Erikoiskaarevuudet M, L, L+ - Beauty Academy By Christina Cecilia",
        "2020 Muotoilu, erikoismuotoilut - Beauty Academy By Christina Cecilia",
        "2020 Working with advanced curls - Adorn'D Beauty",
        "2020 Volym next level - Look by Linn",
        "2021 Liimakoulutus - Cristel Studio",
        "2021 2nd place Classic lashes - NBClassic Gala",
        "2021 2nd place L/M volumelashes Expert - Fablash Winter Competition",
        "2021 3rd place 4-7D volumelashes Expert - Fablash Winter Competition",
        "2022 Super course for eyelash - Oksana Tkachenko",
        "2022 Perfect retention online webinar - Aisha Jagieva, Lotus Lash School",
        "2022 Styling 2.0 - Anastasiia Hutsaliuk, Angelash Professional",
        "2022 Relaxing Beauty - Basic Lash Lift",
        "2022 1st place Lash lift - Cristel Studio Lash Champ",
        "2023 3rd place Lash lift - Oscars Online Competition",
      ],
    },
    {
      name: "Michaela",
      image: "./images/michaela.jpg",
      title: "Mikä?",
      about:
        "Moikka! Olen Michaela, 27, tarjoan Adelia’n Beautyssa kosmetologin palveluita varmoin ja rentouttavin ottein. Opiskelen hius - ja kauneudenhoitoalalla, josta valmistun kosmetologiksi keväällä -24. Kauneudenhoitoalan yrittäjyyden aloitin elokuussa -23, jolloin sain kunnian tehdä hoitolatyötä ensimmäistä kertaa entisessä Kauneuskeskus BB:ssä. Pääsin sieltä siirtymään Adelia’n Beautyyn sen ihanan omistajan, Niinan, työkaveriksi. Työssäni vaalin ammattitaitoa ja rentoa, mutta hienotunteista asennetta, sekä asiakaslähtöisyyttä. Toivon rakkauden työtäni kohtaan välittyvän myös jokaiseen asiakkaaseen asti. Lämpimästi tervetuloa Adelia’an hemmoteltavaksi!",
      certificates: [],
    },
  ];
  return (
    <Container>
      <PageTitle>Henkilökunta</PageTitle>
      <div className="mb-6 text-center col">
        <Fade>
          <Title>Henkilökunta</Title>
        </Fade>
      </div>
      <Fade>
        <div className="grid justify-between grid-cols-1 gap-40 md:grid-cols-2">
          {workers.map((worker, key) => (
            <div key={key} className="worker">
              <img
                src={worker.image}
                alt={worker.name}
                className="w-2/3 mx-auto mb-6 md:w-auto"
              />
              <Title sm>{worker.name}</Title>
              <p className="mb-6">{worker.about}</p>
              {worker.certificates.length > 0 ? (
                <div>
                  <Subtitle>Koulutushistoria</Subtitle>
                  {worker.certificates?.map((certificate, key) => (
                    <p key={key}>{certificate}</p>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </Fade>
    </Container>
  );
}
export default About;
