import React from 'react';
import { Helmet } from 'react-helmet';

function PageTitle({ children }) {
  return (
    <Helmet>
      <title>{children ? `${children} | ` : ''} Adelia'n Beauty</title>
    </Helmet>
  );
}

export default PageTitle;
