import React from "react";

function PriceListItem({ product, price, className }) {
  return (
    <div className={`grid grid-cols-4 py-2 border-bottom-dotted ${className}`}>
      <div className='col-span-3'>{product}</div>{" "}
      <div className='text-right font-bold'>
        {parseInt(price).toFixed(2).replace(".", ",")}€
      </div>
    </div>
  );
}

export default PriceListItem;
