import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button.jsx";
import Fade from "../components/Fade.jsx";

function Hero() {
  return (
    <div className="h-full hero min-h-12" style={{ alignItems: "center" }}>
      <div className="container w-full text-left">
        <div className="max-w-3xl">
          <Fade bottom>
            <span className="subtitle">Niina Seila</span>
            <h3 className="text-3xl title md:text-6xl">
              "Tärkeintä työssäni on laatu ja asiakasturvallisuus"
            </h3>
            {/* <div className="mt-20">
              <a
                href="/booking"
                style={{ width: "auto", display: "inline-block" }}
              >
                <Button>Ajanvaraus</Button>
              </a>
            </div> */}
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Hero;
