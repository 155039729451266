import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import MenuLineIcon from 'remixicon-react/MenuLineIcon';
import Fade from '../components/Fade.jsx';
import logo from '../images/logo-black-new.png';
import Dialog from './Dialog';
import MenuLink from './MenuLink';

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const refMenu = useRef(false);

  const handleClick = () => {
    refMenu.current = true;
    setShowMenu(refMenu.current);
  };

  const hideMenu = () => {
    setShowMenu(refMenu.current);
    refMenu.current = false;
  };

  const menuItems = [
    { path: '/', label: 'Etusivu' },
    // { path: "/booking", label: "Ajanvaraus" },
    { path: '/trainings', label: 'Koulutukset' },
    { path: '/pricing', label: 'Hinnasto' },
    { path: '/about', label: 'Henkilökunta' },
    { path: '/contact', label: 'Yhteystiedot' },
  ];
  return (
    <div className='navbar' style={{ height: '120px' }}>
      <div className='container h-full px-2 mx-auto md:px-0'>
        <div className='flex items-center justify-between w-full h-full'>
          <Fade>
            <Link to='/'>
              <img src={logo} alt="Adelia'n Beauty" />
            </Link>
          </Fade>

          <div className='flex-none custom-menu'>
            <ul className='p-0 font-semibold menu menu-horizontal'>
              {menuItems.map((menu, key) => (
                <MenuLink key={key} path={menu.path} title={menu.label} />
              ))}
            </ul>
          </div>

          <div className='flex-none mobile-menu'>
            <ul className='p-0 menu menu-horizontal'>
              <MenuLineIcon
                className='mt-2 font-semibold cursor-pointer'
                onClick={handleClick}
              />
            </ul>
          </div>
        </div>
      </div>
      <Dialog showMenu={refMenu.current} hideMenu={hideMenu} />
    </div>
  );
}

export default Navbar;
