import React, { useState } from "react";
import Title from "./Title";
import { ReactComponent as Arrow } from "../images/arrow-right-btn.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

function ContactForm() {
  const [puzzle, setPuzzle] = useState({
    firstNumber: Math.floor(Math.random() * 10),
    secondNumber: Math.floor(Math.random() * 10),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      puzzle: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nimi vaaditaan"),
      email: Yup.string()
        .email("Virheellinen sähköpostiosoite")
        .required("Sähköpostiosoite vaaditaan"),
      message: Yup.string().required("Viesti vaaditaan"),
      puzzle: Yup.number()
        .required("Vahvistusvastaus vaaditaan")
        .test("puzzle-test", "Väärä vastaus", function (value) {
          return puzzle.firstNumber + puzzle.secondNumber === value;
        }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      fetch(
        `/api/sendMail.php?name=${encodeURIComponent(
          formik.values.name
        )}&email=${encodeURIComponent(
          formik.values.email
        )}&message=${encodeURIComponent(formik.values.message)}`
      )
        .then((response) => {
          setLoading(false);
          setMessageSent(true);
        })
        .catch((err) => {
          console.log("Failed to send email", err);
          setLoading(false);
          setErrorMessage(err);
        });
    },
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  return (
    <>
      {loading ? (
        "Lähetetään..."
      ) : messageSent ? (
        <div>
          <div className='text-center'>
            <Title>Kiitoksia!</Title>
          </div>
          <p className='mt-10 text-center'>
            Yhteydenotto lähetettiin onnistuneesti.
          </p>
        </div>
      ) : (
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          style={{ width: "auto", maxWidth: "900px", margin: "0 auto" }}
        >
          <div className='text-left'>
            <Title>Ota yhteyttä</Title>
          </div>
          <div className='form-group'>
            <label>Nimi: *</label>
            <input
              onChange={formik.handleChange}
              name='name'
              value={formik.values.name}
              type='text'
              className='form-control'
              required
            />
            {formik.errors.name && (
              <p className='text-red-700 mt-2'>{formik.errors.name}</p>
            )}
          </div>

          <div className='form-group'>
            <label>Sähköpostiosoite: *</label>
            <input
              onChange={formik.handleChange}
              name='email'
              type='email'
              value={formik.values.email}
              className='form-control'
              required
            />
            {formik.errors.email && (
              <p className='text-red-700 mt-2'>{formik.errors.email}</p>
            )}
          </div>

          <div className='form-group'>
            <label>Viesti: *</label>
            <textarea
              onChange={formik.handleChange}
              name='message'
              value={formik.values.message}
              className='form-control'
              rows={5}
              required
            ></textarea>
            {formik.errors.message && (
              <p className='text-red-700 mt-2'>{formik.errors.message}</p>
            )}
          </div>
          <div className='form-group'>
            <label>
              Kuinka paljon on {puzzle.firstNumber}+{puzzle.secondNumber}? *
            </label>
            <input
              onChange={formik.handleChange}
              name='puzzle'
              value={formik.values.puzzle}
              className='form-control'
              rows={5}
              required
            />
            {formik.errors.message && (
              <p className='text-red-700 mt-2'>{formik.errors.puzzle}</p>
            )}
          </div>
          <div className='form-group text-center mt-10'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={!formik.isValid && formik.dirty}
            >
              <p className='btn-content'>
                Lähetä <Arrow className='ml-4 hero-arrow' />
              </p>
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export default ContactForm;
